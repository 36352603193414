.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.GlavniyText {
  font-family: monospace;
  font-size: 5vw;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  margin-bottom: 1rem;
  margin-top: 1rem;
  margin-left: 10%;
  margin-right: 10%;
}
img {
  width: 100%;
  height: 100%;
}
body {
  background-color: rgb(67, 67, 67);
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
}

.block:not(:last-child) {
  margin-bottom: 1rem;
}
.textAbs {
  margin-top: 10%;
  text-align: center;
  color: black;
  position: absolute;
}
.carousel {
  width: 100vw;
  height: 100vh;
}
.slide {
  width: 100vw;
  height: 100vh;
}
.carousel__slider {
  width: 100vw;
  height: 100vh;
}
/* .navbar-item{
  width: 100px ;
  height: 75px;
  padding: 0%;
  margin-right: 1rem;
} */
nav.navbar {
  height: 5rem !important;
  width: 100%;
  position: fixed;
}
.logo {
  width: 100%;
  height: 100%;
}
.carouselCentre {
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 1rem;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}
.container {
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
h2 {
  font-size: calc(1em + 2vw);
  font-family: "Gambetta", serif;
  letter-spacing: 0px;
  transition: 700ms ease;
  font-variation-settings: "wght" 311;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  color: rgb(223, 223, 222);
  outline: none;
  text-align: center;

  scroll-margin-top: 5rem;
}

h1 {
  word-wrap: break-word;
  width: auto;
  font-size: calc(2em + 3.5vw);
  text-transform: uppercase;
  font-family: "Gambetta", serif;
  letter-spacing: -3px;
  transition: 700ms ease;
  font-variation-settings: "wght" 311;
  margin-bottom: 0.8rem;
  margin-top: 0.8;
  color: rgb(208, 208, 208);
  outline: none;
  text-align: center;
}

h1:hover {
  font-variation-settings: "wght" 582;
  letter-spacing: 1px;
}

p {
  font-size: 1.2em;
  line-height: 150%;
  text-align: center;
  color: MintCream;
  letter-spacing: 0.5px;
}
.message-body {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}
.columns {
  margin-right: 0;
  margin-left: 0;
}
.image {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}
.navbar-menu{
  background-color: #363636;
}
.navbar-item{
  color: white;
  background-color: #363636;
}
